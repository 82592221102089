<template>
  <v-app>
    <v-snackbar v-model="snackbar" top color="orange" :timeout="8000">
      {{
        $tc("passwordExpireNotificationMessage", passwordExpireNotification, {
          count: passwordExpireNotification
        })
      }}
      <v-btn dark text @click="snackbar = false">
        {{ $t("buttons.close") }}
      </v-btn>
    </v-snackbar>
    <app-header />
    <router-view />
    <notification-bar />
    <gdpr-acceptance-dialog />
  </v-app>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "app-layout",
    components: {
      "app-header": () => import("@/layouts/app-header"),
      GdprAcceptanceDialog: () => import("@/components/GdprAcceptanceDialog")
    },
    data: () => ({
      sidebar: false,
      snackbar: false
    }),
    computed: {
      ...mapState("auth", ["passwordExpireNotificationStatus", "passwordExpireNotification"])
    },
    watch: {
      passwordExpireNotificationStatus: {
        immediate: true,
        handler(val) {
          this.snackbar = val;
        }
      }
    },
    methods: {
      navigate() {
        this.$router.push({ name: "dashboard" });
      },
      onClickLogout() {
        this.$store.dispatch("auth/LOGOUT");
      },
      async init() {
        this.$store.commit("auth/SET_LOADING", true);
        await Promise.all([
          this.$store.dispatch("auth/FETCH_AUTHENTICATED_EMPLOYEE"),
          this.$store.dispatch("auth/FETCH_ORGANIZATION_DETAIL")
        ]);
        this.$store.commit("auth/SET_LOADING", false);
      }
    },
    created() {
      this.init();
    }
  };
</script>
